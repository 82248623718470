<template lang="html">
  <div id="container">
    <vx-card title="Resultados" class="">
      <vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePrompt">
          <input v-model="fileName" placeholder="Nombre del archivo" class="w-full" />
          <v-select v-model="selectedFormat" :options="formats" class="my-4" />
          <div class="flex">
            <span class="mr-4">Tamaño de la celda automático:</span>
            <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
          </div>
      </vs-prompt>
      <vs-button @click="activePrompt=true;popupActive=false;" class="export">Exportar a tabla de Excel</vs-button>
      <br>
      <br>
      <vs-table :data="items" max-items="10" pagination>
        <template slot="thead">
          <vs-th>Organizacion</vs-th>
          <vs-th>Broker Email</vs-th>
          <vs-th>Cantidad de consultas</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].Organizacion">
              {{ data[indextr].Organizacion }}
            </vs-td>
            <vs-td :data="data[indextr].BrokerEmail">
              {{ data[indextr].BrokerEmail }}
            </vs-td>
            <vs-td :data="data[indextr].Cantidad">
              {{ data[indextr].Cantidad }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  data(){
    return{
      fileName: '',
      formats:['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      activePrompt: false,
      selectedFormat: 'xlsx',
      headerTitle: ['Organizacion', 'Broker Email', 'Cantidad de consultas'],
      headerVal: ['Organizacion', 'BrokerEmail', 'Cantidad'],
    }
  },
  watch: {
  },
  mounted(){
  },
  computed:{
  },
  methods:{
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = this.items
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
  }
}
</script>

<style lang="css" scoped>
.vdp-datepicker__calendar{
  position: fixed !important;

}
</style>
